import "./css/About.css";

export const About = () => {
  return (
    <div className="about-us">
      <h2>Tentang qasuary</h2>
      <p>
        Qasuary merupakan portal karir yang dikembangkan untuk mengintegrasikan
        BKK (Koordinator Karir SMK), Perusahaan sekaligus Alumni dengan
        fitur-fitur eksklusif dan relevan bagi setiap stakeholdernya.
      </p>
      <a href="#!">pelajari lebih lanjut</a>
    </div>
  );
};
