import "./css/card.css";
import hashids from "../Hash";
export const CardPerusahaan = (props) => {
  const { data } = props;
  return (
    <>
      {data.map((v, i) => {
        return (
          <a
            key={i}
            href={
              process.env.PUBLIC_URL + "/perusahaan/" + hashids.encode(1, v.id)
            }
          >
            <div className="perusahaan" key={i}>
              <div className="info">
                <div className="logo">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/img/company/pp/" +
                      v.profile_picture
                    }
                    id="logo-perusahaan"
                    alt="logo-perusahaan"
                  />
                </div>
                <div className="perusahaan-text">
                  <h3>{v.name}</h3>
                </div>
                <div className="deskripsi">
                  <p>{v.description}</p>
                </div>
              </div>
              <div className="location">
                <h4>{JSON.parse(v.lokasi).kota}</h4>
              </div>
            </div>
          </a>
        );
      })}
    </>
  );
};
