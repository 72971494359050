import { useEffect, useState } from "react";
// import { data } from "./job/dummy";
// import { getDate } from "./job/JobFinder";
// import { refreshToken } from "../services/refreshToken";
import "../css/Home.css";
import { Header } from "../components/home/Header";
import { About } from "../components/home/About";
import { Steps } from "../components/home/Steps";
import { Company } from "../components/home/Company";
import { NavbarMenu } from "../components/navbar/NavbarMenu";
import { Vacancy } from "../components/home/Vacancy";
import { Info } from "../components/home/Info";
import { Footer } from "../components/footer/Footer";
import axios from 'axios';

export const Home = () => {
  // const newestJobs = data.sort((a, b) => getDate(b) - getDate(a));

  return (
    <div style={{ width: "inherit" }}>
      <NavbarMenu beranda={"active"} />
      <Header />
      <main id="JobList">
        <div className="con white">
          <About />
          <Steps />
        </div>
        <div className="con gray">
          <Company/>
          <Vacancy />
          <Info />
        </div>
        <Footer />
      </main>
    </div>
  );
};
