import NoFound from "../img/illust/No-Found.svg";
import NoPages from "../img/illust/404.svg";
import NoNotif from "../img/illust/notif.svg";
import "../css/Error.css";

export const Errors = (props) => {
  let imgType;
  if (props.type === "NoFound") {
    imgType = NoFound;
  } else if (props.type === "NoPages") {
    imgType = NoPages;
  } else if (props.type === "NoNotif") {
    imgType = NoNotif;
  }
  return (
    <>
      <div className="Error">
        <div className="err-content">
          <img src={imgType} alt={props.type} />
          <p>{props.msg}</p>
        </div>
      </div>
    </>
  );
};
