import { NavbarMenu } from "../../components/navbar/NavbarMenu";
import "../../css/Dashboard.css";
import { Footer } from "../../components/footer/Footer";
import { useState, useEffect } from "react";
import axios from "axios";
import { CardLowongan } from "../../components/cards/CardLowongan";

export const DashboardCom = () => {
  const [company, setCompany] = useState([]);

  const fetchCompany = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/test/get/opening")
      .then(function (response) {
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <>
      <NavbarMenu dashboard_login={true} dashboard={"active"} />
      <main>
        <div className="dash-content">
          <div className="content-lowongan">
            <div className="between">
              <h2>Lowongan</h2>
              <a>Buat Baru</a>
            </div>
            <div className="contain-co">
              <CardLowongan data={company} />
            </div>
          </div>
          <div className="content-alumni">
            <div className="between">
              <h2>Data Alumni</h2>
              <a>Lihat Semua</a>
            </div>
            <div className="contain-co">
              <table>
                <tr>
                  <th>Nama</th>
                  <th>Jenis Kelamin</th>
                  <th>Jurusan</th>
                  <th>Status</th>
                  <th>Tgl Lahir</th>
                  <th>Tinggi</th>
                  <th>Berat</th>
                  <th>Aksi</th>
                </tr>
                <tr>
                  <td>Muhammad Erlangga Darmawan</td>
                  <td>Laki - Laki</td>
                  <td>RPL</td>
                  <td>Bekerja</td>
                  <td>30-09-2004</td>
                  <td>173</td>
                  <td>56</td>
                  <td>
                    <a href="#!">detail</a>
                  </td>
                </tr>
                <tr>
                  <td>Charles Daniel</td>
                  <td>Laki - Laki</td>
                  <td>RPL</td>
                  <td>Kuliah</td>
                  <td>22-06-2004</td>
                  <td>171</td>
                  <td>54</td>
                  <td>
                    <a href="#!">detail</a>
                  </td>
                </tr>
                <tr>
                  <td>Wahyu Hidayat</td>
                  <td>Laki - Laki</td>
                  <td>RPL</td>
                  <td>Bekerja</td>
                  <td>12-07-2003</td>
                  <td>172</td>
                  <td>56</td>
                  <td>
                    <a href="#!">detail</a>
                  </td>
                </tr>
                <tr>
                  <td>Wulang Dhanadipa</td>
                  <td>Laki - Laki</td>
                  <td>RPL</td>
                  <td>Tidak Bekerja</td>
                  <td>11-10-2003</td>
                  <td>172</td>
                  <td>41</td>
                  <td>
                    <a href="#!">detail</a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
