export let Datajurusan = [
  {
    jurusan: "Akuntansi",
    value: "AK",
  },
  {
    jurusan: "Teknik Mesin",
    value: "TP",
  },
  {
    jurusan: "Teknik Pengelasan",
    value: "TPL",
  },
  {
    jurusan: "Teknik Komputer dan Jaringan",
    value: "TKJ",
  },
  {
    jurusan: "Rekayasa Perangkat Lunak",
    value: "RPL",
  },
  {
    jurusan: "Multimedia",
    value: "MM",
  },
  {
    jurusan: "Tata Busana",
    value: "BB",
  },
  {
    jurusan: "Teknik Kendaraan Ringan",
    value: "TKR",
  },
];
