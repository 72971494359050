import { useParams } from "react-router-dom";
import { perusahaan } from "../../data/company-dummy";

export const CardDetailPerusahaan = (props) => {
  const { data, lokasi } = props;

  console.log(data.lokasi);

  return (
    <>
      <div className="card-slider">
        <div className="com-card">
          <div className="com-card-icon">
            <img
              src={process.env.PUBLIC_URL + "/img/icons/company/location.svg"}
              alt="icon"
            />
          </div>
          <div className="com-card-info">
            <h3>Alamat</h3>
            <p>{lokasi.kota}</p>
          </div>
        </div>
        <div className="com-card">
          <div className="com-card-icon">
            <img
              src={process.env.PUBLIC_URL + "/img/icons/company/sms.svg"}
              alt="icon"
            />
          </div>
          <div className="com-card-info">
            <h3>E-mail</h3>
            <p>{data.email}</p>
          </div>
        </div>
        <div className="com-card">
          <div className="com-card-icon">
            <img
              src={process.env.PUBLIC_URL + "/img/icons/company/link.svg"}
              alt="icon"
            />
          </div>
          <div className="com-card-info">
            <h3>Website</h3>
            <p>
              <a href={data.website}>{data.website}</a>
            </p>
          </div>
        </div>
        <div className="com-card">
          <div className="com-card-icon">
            <img
              src={process.env.PUBLIC_URL + "/img/icons/company/call.svg"}
              alt="icon"
            />
          </div>
          <div className="com-card-info">
            <h3>Nomor Telepon</h3>
            <p>{data.no_telp}</p>
          </div>
        </div>
      </div>
    </>
  );
};
