import { Hero } from "../../components/hero/Hero";
import { NavbarMenu } from "../../components/navbar/NavbarMenu";
import { Footer } from "../../components/footer/Footer";
import "../../css/Akun.css";
import { Uni } from "../Uni";
import { Datajurusan } from "../../data/jurusan";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const AkunEdit = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [gender, setGender] = useState("");
  const [jobs, setJobs] = useState("");
  const [search, setSearch] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");

  const handleUpdateAccount = (e) => {
    try {
      e.preventDefault();
      axios.post(process.env.REACT_APP_API + "/test/post", {
        name: name,
        jurusan: jurusan,
        gender: gender,
        jobs: jobs,
        search: search,
        phone_number: phone_number,
        address: address,
        description: description,
      });
      navigate("/akun");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <NavbarMenu />
      <div className="account-hero" style={{ paddingBottom: 34 }}>
        <Hero
          cover={"/img/account/cover/cover.svg"}
          pp={"/img/account/pp/pp.png"}
        />
      </div>
      <div className="acc-content edit">
        <a href="#!">Ganti Foto Profil</a>
        <div className="content-top">
          <form>
            <div className="form-top">
              <div>
                <label for="nama">Nama</label>
                <input
                  name="nama"
                  id="nama"
                  placeholder="Masukan Nama"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div></div>
              <div>
                <label for="status">Status</label>
                <div className="select-inside">
                  <select
                    name="status"
                    id="status"
                    value={jobs}
                    onChange={(e) => {
                      setJobs(e.target.value);
                    }}
                  >
                    <option value="">Belum dipilih</option>
                    <option value="Kuliah">Kuliah</option>
                    <option value="Bekerja">Bekerja</option>
                    <option value="Menganggur">Tidak Bekerja / Kuliah</option>
                  </select>
                  <img
                    src={process.env.PUBLIC_URL + "/img/icons/SelectDown.svg"}
                    alt="select-down-icon"
                  />
                </div>
              </div>
              <div
                id="universitas"
                style={{ display: jobs === "Kuliah" ? "block" : "none" }}
              >
                <label for="uni">Nama Universitas</label>
                <Uni />
              </div>
              <div
                id="perusahaan"
                style={{ display: jobs === "Bekerja" ? "block" : "none" }}
              >
                <label for="perusahaan">Nama Perusahaan</label>
                <input
                  name="perusahaan"
                  id="perusahaan"
                  placeholder="Masukan Nama Perusahaan"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              <div>
                <label for="ringkasan">Ringkasan</label>
                <textarea
                  name="ringkasan"
                  id="ringkasan"
                  placeholder="Deskripsikan Diri Anda ..."
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-body">
              <div className="kiri">
                <h2>Kontak</h2>
                <div>
                  <label for="no">No Telepon</label>
                  <input
                    name="no"
                    id="no"
                    placeholder="Masukan Nomor Telepon"
                    value={phone_number}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label for="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Masukan Email"
                  />
                </div>
                <div>
                  <label for="alamat">Alamat</label>
                  <textarea
                    name="alamat"
                    id="alamat"
                    placeholder="Masukan Alamat"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="kanan">
                <h2>Data Pribadi</h2>
                <div>
                  <label for="ttl">Tempat & Tanggal Lahir</label>
                  <input
                    name="ttl"
                    id="ttl"
                    placeholder="Masukan Tempat & Tanggal Lahir"
                  />
                </div>
                <div>
                  <label for="gender">Jenis Kelamin</label>
                  <div className="select-inside">
                    <select
                      name="gender"
                      id="gender"
                      value={gender}
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                    >
                      <option value="">Belum dipilih</option>
                      <option value="Laki laki">Pria</option>
                      <option value="Perempuan">Wanita</option>
                    </select>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/SelectDown.svg"}
                      alt="select-down-icon"
                    />
                  </div>
                </div>
                <div>
                  <label for="jurusan">Jurusan</label>
                  <div className="select-inside">
                    <select
                      name="jurusan"
                      id="jurusan"
                      value={jurusan}
                      onChange={(e) => {
                        setJurusan(e.target.value);
                      }}
                    >
                      <option value="">Belum dipilih</option>
                      {Datajurusan.map((v, i) => {
                        return (
                          <option value={v.value} key={i}>
                            {v.jurusan}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/SelectDown.svg"}
                      alt="select-down-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-bottom">
              <h2>Pengalaman</h2>
              <a>Tambah</a>
              <div>
                <label for="jabatan">Jabatan</label>
                <input
                  name="jabatan"
                  id="jabatan"
                  placeholder="Masukan Jabatan"
                />
              </div>
              <div>
                <label for="tipe">Tipe Pekerjaan</label>
                <input
                  name="tipe"
                  id="tipe"
                  placeholder="Masukan Tipe Pekerjaan"
                />
              </div>
              <div>
                <label for="nama-perusahaan">Nama Perusahaan</label>
                <input
                  name="nama-perusahaan"
                  id="nama-perusahaan"
                  placeholder="Masukan Nama Perusahaan"
                />
              </div>
              <div>
                <label for="tanggal">Tanggal Masuk & Keluar</label>
                <div className="date-range">
                  <input type="date" name="tanggal" id="tanggal" />
                  <input type="date" name="tanggal" id="tanggal" />
                </div>
              </div>
              <div className="form-deskripsi">
                <label for="deskripsi">Deskripsi</label>
                <textarea
                  name="deskripsi"
                  id="deskripsi"
                  placeholder="Deskripsikan Pengalaman Anda ..."
                />
              </div>
              <a className="hapus">Hapus</a>
            </div>
            <div className="form-bottom">
              <h2>Pendidikan</h2>
              <a>Tambah</a>
              <div>
                <label for="nama-sekolah">Nama Sekolah</label>
                <input
                  name="nama-sekolah"
                  id="nama-sekolah"
                  placeholder="Masukan Nama Sekolah"
                />
              </div>
              <div>
                <label for="jurusan-sekolah">Jurusan</label>
                <input
                  name="jurusan-sekolah"
                  id="jurusan-sekolah"
                  placeholder="Masukan Jurusan"
                />
              </div>
              <div>
                <label for="tanggal-sekolah">Tanggal Masuk & Keluar</label>
                <div className="date-range">
                  <input
                    type="number"
                    name="tanggal-sekolah"
                    id="tanggal-sekolah"
                    min="1900"
                    max="2099"
                    step="1"
                    placeholder="Tahun Masuk"
                  />
                  <input
                    type="number"
                    name="tanggal-sekolah"
                    id="tanggal-sekolah"
                    min="1900"
                    max="2099"
                    step="1"
                    placeholder="Tahun Lulus"
                  />
                </div>
              </div>
              <div></div>
              <a className="hapus">Hapus</a>
            </div>
            <div className="form-berkas">
              <h2>Berkas</h2>
              <a>Tambah</a>
              <a href="#!" className="berkas">
                <div>
                  <i>Document</i>
                  <h5>Nilai Alumni.pdf</h5>
                </div>
                <i>Eye</i>
              </a>
              <a href="#!" className="berkas">
                <div>
                  <i>Document</i>
                  <h5>Ijazah.pdf</h5>
                </div>
                <i>Eye</i>
              </a>
              <a href="#!" className="berkas">
                <div>
                  <i>Document</i>
                  <h5>Sertifikat.pdf</h5>
                </div>
                <i>Eye</i>
              </a>
            </div>
          </form>
        </div>
        <div className="content-bottom">
          <a href="javascript:void(0)" onClick={handleUpdateAccount}>
            Simpan
          </a>
          <a href="/akun">Batal</a>
        </div>
      </div>
      <Footer />
    </>
  );
};
