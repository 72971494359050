import "./css/Footer.css";
const data = [
  {
    nama: "Beranda",
    link: "/",
  },
  {
    nama: "Lowongan",
    link: "/lowongan",
  },
  {
    nama: "Perusahaan",
    link: "/perusahaan",
  },
  {
    nama: "Berita",
    link: "/informasi",
  },
  {
    nama: "Tentang qasuary",
    link: "/tentang",
  },
  {
    nama: "Pesan",
    link: "/",
  },
  {
    nama: "Akun",
    link: "/akun",
  },
  {
    nama: "",
    link: "",
  },
];
export const Footer = () => {
  return (
    <footer>
      <div className="flex">
        <div className="foot-1">
          <img
            src={process.env.PUBLIC_URL + "/qasuary-white-foot.svg"}
            alt="Qasuary"
          />
          <h3>
            Qasuary merupakan portal karir yang dikembangkan untuk memberikan
            kemudahan kepada para alumni untuk
          </h3>
          <h4 className="copyright">@2022 qasuary, All rights reserved</h4>
        </div>
        <div className="foot-2">
          {data.map((v, i) => {
            return (
              <a href={v.link} key={i}>
                {v.nama}
              </a>
            );
          })}
        </div>
        <div className="foot-3">
          <h3>Kontak BKK</h3>
          <a href="mailto:bkk.smkn1kotabekasi@gmail.com">
            bkk.smkn1kotabekasi@gmail.com
          </a>
          <h4>Jalan Bintara VIII No.2, Bintara, Bekasi Barat, Kota Bekasi</h4>
          <h4>021-8853883</h4>
        </div>
      </div>
    </footer>
  );
};
