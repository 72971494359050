import { NavbarMenu } from "../../components/navbar/NavbarMenu";
import "../../css/DashboardEdit.css";
import { Footer } from "../../components/footer/Footer";

export const VacancyComNew = () => {
  return (
    <>
      <NavbarMenu dashboard_login={true} lowongan={"active"} />
      <div className="acc-content edit">
        <div className="content-top">
          <h1>Buat Lowongan Baru</h1>
          <form>
            <div className="form-top">
              <div>
                <label for="judul">Judul Lowongan</label>
                <input
                  name="judul"
                  id="judul"
                  placeholder="Masukan Judul Lowongan"
                />
              </div>
              <div>
                <label for="berlaku">Berlaku Sampai</label>
                <input type="date" name="berlaku" id="berlaku" />
              </div>
              <div>
                <label for="deskripsi">Deskripsi Pekerjaan</label>
                <textarea
                  name="deskripsi"
                  id="deskripsi"
                  placeholder="Deskripsikan Tentang Pekerjaan ..."
                />
              </div>
            </div>
            <div className="form-body">
              <h2>Informasi Tambahan</h2>
            </div>
            <div className="form-top">
              <div>
                <label for="tahapan">Jumlah Tahapan Seleksi</label>
                <input
                  name="tahapan"
                  id="tahapan"
                  placeholder="Masukan Jumlah Tahapan"
                />
              </div>
              <div>
                <label for="waktu">Jadwal Pelaksanaan</label>
                <input
                  type="datetime-local"
                  name="waktu"
                  id="waktu"
                  placeholder="Masukan Jadwal Pelaksanaan"
                />
              </div>
              <div className="lokasi">
                <label for="lokasi">Lokasi Pelaksanaan</label>
                <textarea
                  name="lokasi"
                  id="lokasi"
                  placeholder="Masukan Lokasi Pelaksanaan"
                  rows="6"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="content-bottom">
          <a href="#!">Kirim</a>
          <a href="/akun">Batal</a>
        </div>
      </div>
      <Footer />
    </>
  );
};
