import { Container, Row, Col } from "react-bootstrap";
import "./css/Header.css";

export const Header = () => {
  return (
    <header id="home-header">
      <Container>
        <Row>
          <Col sm={12} lg={5} className="left-container">
            <h1>
              Temukan <span id="header-label">pekerjaan</span> yang kamu impikan
            </h1>
            <p>
              qasuary akan membantu alumni dalam mencari pekerjaan dengan lebih
              cepat, mudah dan cocok
            </p>
            <div className="header-search">
              <div className="search-box">
                <img
                  src={process.env.PUBLIC_URL + "/img/icons/Search.svg"}
                  alt=""
                />
                <input
                  type="text"
                  placeholder="Cari Lowongan . ."
                  name=""
                  id=""
                />
              </div>
              <button type="button">Cari</button>
            </div>
          </Col>
          <Col sm={12} lg={7} className="right-container"></Col>
        </Row>
      </Container>
    </header>
  );
};
