import { CardLowongan } from "../components/cards/CardLowongan";
import { HeaderAlter } from "../components/container/HeaderAlter";
import { Footer } from "../components/footer/Footer";
import { NavbarMenu } from "../components/navbar/NavbarMenu";
// import { lowongan } from "../data/vacancy-dummy";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Datajurusan } from "../data/jurusan";
import axios from "axios";
let filteredData;
let paramSearch = false;
export const Vacancy = () => {
  const [lowongan, setLowongan] = useState([]);

  const fetch = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/test/get/opening")
      .then((response) => {
        setLowongan(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(localStorage.getItem("search"));
  const [select, setSelect] = useState(localStorage.getItem("select"));
  function filterSearch(query) {
    setSearch(query);
    localStorage.setItem("search", query);
  }
  function filterSelect(query) {
    setSelect(query);
    localStorage.setItem("select", query);
  }
  var psearch = searchParams.get("searchLowongan");
  var pselect = searchParams.get("filterJurusan");
  if (psearch !== null || pselect !== null) {
    if (paramSearch === false) {
      if (psearch !== "" && pselect === "") {
        filterSearch(psearch);
        filterSelect(pselect);
      } else if (psearch === "" && pselect !== "") {
        filterSearch(psearch);
        filterSelect(pselect);
      } else {
        filterSearch(psearch);
        filterSelect(pselect);
      }
      paramSearch = true;
    }
    if (psearch !== "" && pselect === "") {
      filteredData = lowongan.filter((item) =>
        item.title.toLowerCase().includes(psearch.toLowerCase())
      );
    } else if (psearch === "" && pselect !== "") {
      filteredData = lowongan.filter((item) =>
        item.jurusan.toLowerCase().includes(pselect.toLowerCase())
      );
    } else if (psearch !== "" && pselect !== "") {
      filteredData = lowongan.filter(
        (item) =>
          item.jurusan.toLowerCase().includes(pselect.toLowerCase()) &&
          item.title.toLowerCase().includes(psearch.toLowerCase())
      );
    }
  } else {
    if (paramSearch === false) {
      filterSearch("");
      filterSelect("");
      paramSearch = true;
    }
  }
  return (
    <>
      <div className="con gray navbar-expad">
        <NavbarMenu lowongan={"active"} />
        <HeaderAlter
          title={"Telusuri Lowongan"}
          banner={"lowonganBanner"}
          search={"show"}
        />
        {/* search bar */}
        <div className="search-bar">
          <form
            action={window.location.href}
            method="GET"
            className="header-search"
          >
            <div className="search-box lowongan">
              <div className="search">
                <img
                  src={process.env.PUBLIC_URL + "/img/icons/Search.svg"}
                  alt="search-icon"
                />
                <input
                  type="text"
                  placeholder="Cari Lowongan . ."
                  name="searchLowongan"
                  id="searchLowongan"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              {/* select inside */}
              <div className={"select-inside"}>
                <select
                  name="filterJurusan"
                  id="filterJurusan"
                  className="filterJurusan"
                  value={select}
                  onChange={(e) => {
                    setSelect(e.target.value);
                  }}
                >
                  <option value="">Semua Jurusan</option>
                  {Datajurusan.map((v, i) => {
                    return (
                      <option value={v.value} key={i}>
                        {v.jurusan}
                      </option>
                    );
                  })}
                </select>
                <img
                  src={process.env.PUBLIC_URL + "/img/icons/SelectDown.svg"}
                  alt="select-down-icon"
                />
              </div>
            </div>
            <button type="submit">Cari</button>
          </form>
        </div>
        {/* select field only visible in mobile*/}
        <div className={"select-area"}>
          <select
            name="filterJurusan"
            id="filterJurusan"
            className="filterJurusan"
            value={select}
            onChange={(e) => {
              setSelect(e.target.value);
            }}
          >
            <option value="">Semua Jurusan</option>
            {Datajurusan.map((v, i) => {
              return (
                <option value={v.value} key={i}>
                  {v.jurusan}
                </option>
              );
            })}
          </select>
          <img
            src={process.env.PUBLIC_URL + "/img/icons/SelectDown.svg"}
            alt="select-down-icon"
          />
        </div>
        <div className="company">
          <div className="contain-co">
            <CardLowongan
              data={filteredData !== undefined ? filteredData : lowongan}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
