import "./css/card.css";
import hashids from "../Hash";
export const CardLowongan = (props) => {
  const { data } = props;

  return (
    <>
      {data.map((v, i) => {
        return (
          <a
            className="no-under"
            key={i}
            href={
              process.env.PUBLIC_URL + "/lowongan/" + hashids.encode(2, v.id)
            }
          >
            <div className="perusahaan lowongan" key={i}>
              <div className="info">
                <div className="flex">
                  <img
                    id="logo-perusahaan"
                    src={
                      process.env.PUBLIC_URL +
                      "/img/company/pp/" +
                      v.profile_picture
                    }
                    alt="logo-perusahaan"
                  />
                  <div className="perusahaan-title">
                    <h3>{v.name}</h3>
                    <p>{JSON.parse(v.lokasi).kota}</p>
                  </div>
                </div>
                <div>
                  <h3>{v.title}</h3>
                  <p>{JSON.parse(v.description).desc}</p>
                </div>
              </div>
            </div>
          </a>
        );
      })}
    </>
  );
};
