import "./css/navbar.css";
import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";

export const NavbarMenu = (props) => {
  const [scrollY, setScrollY] = useState(0);
  const isLogin = true;

  function logit(v) {
    setScrollY(v);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", function () {
        if (window.scrollY > 0) {
          logit("scrolled");
        } else {
          logit("");
        }
      });
    }
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", logit);
    };
  }, []);
  if (props.dashboard_login === true) {
    return (
      <Navbar collapseOnSelect expand="md" fixed="top" className={scrollY}>
        <Container>
          <Navbar.Brand href={process.env.REACT_APP_ORIGIN}>
            <img
              src={process.env.PUBLIC_URL + "/qasuary-logo.png"}
              className="d-md-none d-lg-block"
              alt="qasuary-logo"
            />
            <img
              src={process.env.PUBLIC_URL + "/qasuary-192.png"}
              className="d-none d-md-block d-lg-none"
              alt="qasuary-logo-only"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className={props.dashboard} href="/dashboard">
                Dashboard
              </Nav.Link>
              <Nav.Link className={props.lowongan} href="/dashboard/lowongan">
                Lowongan
              </Nav.Link>
              <Nav.Link className={props.alumni} href="/dashboard/alumni">
                Alumni
              </Nav.Link>
            </Nav>
            <Nav>
              <a href="login" className={isLogin === true ? "d-none" : "masuk"}>
                Masuk
              </a>
              <a
                href="akun"
                className={isLogin === true ? "akun-link masuk" : "d-none"}
                style={{ border: "none" }}
              >
                <img
                  src="/img/account/pp/pp.png"
                  alt="profil-pic"
                  className="d-none d-md-block"
                />
                <p
                  className={
                    "akun-text-link " + props.akun + " d-sm-block d-md-none"
                  }
                  style={{ marginBottom: "0" }}
                >
                  Akun
                </p>
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  } else {
    return (
      <Navbar collapseOnSelect expand="md" fixed="top" className={scrollY}>
        <Container>
          <Navbar.Brand href={process.env.REACT_APP_ORIGIN}>
            <img
              src={process.env.PUBLIC_URL + "/qasuary-logo.png"}
              className="d-md-none d-lg-block"
              alt="qasuary-logo"
            />
            <img
              src={process.env.PUBLIC_URL + "/qasuary-192.png"}
              className="d-none d-md-block d-lg-none"
              alt="qasuary-logo-only"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className={props.beranda} href="/">
                Beranda
              </Nav.Link>
              <Nav.Link className={props.perusahaan} href="/perusahaan">
                Perusahaan
              </Nav.Link>
              <Nav.Link className={props.lowongan} href="/lowongan">
                Lowongan
              </Nav.Link>
              <Nav.Link className={props.informasi} href="/informasi">
                Informasi
              </Nav.Link>
            </Nav>
            <Nav>
              <a href="login" className={isLogin === true ? "d-none" : "masuk"}>
                Masuk
              </a>
              <a
                href="akun"
                className={isLogin === true ? "akun-link masuk" : "d-none"}
                style={{ border: "none" }}
              >
                <img
                  src="/img/account/pp/pp.png"
                  alt="profil-pic"
                  className="d-none d-md-block"
                />
                <p
                  className={
                    "akun-text-link " + props.akun + " d-sm-block d-md-none"
                  }
                  style={{ marginBottom: "0" }}
                >
                  Akun
                </p>
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
};
