import "./css/hero.css";

export const Hero = (props) => {
  return (
    <>
      <div className="co">
        <img
          id="cover"
          src={process.env.PUBLIC_URL + props.cover}
          alt="Cover"
        />
        <img id="pp" src={process.env.PUBLIC_URL + props.pp} alt="Profile" />
      </div>
    </>
  );
};
