import { useEffect, useState } from "react";
import axios from "axios";
import { CardPerusahaan } from "../cards/CardPerusahaan";

export const Company = () => {
  const [company, setCompany] = useState([]);

  const fetchCompany = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/test/get/company")
      .then(function (response) {
        setCompany(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <div className="company">
      <div className="inline">
        <h2>Perusahaan</h2>
        <a id="lihatSemua" href={process.env.PUBLIC_URL + "/perusahaan"}>
          Lihat semua
        </a>
      </div>
      <div className="contain-co">
        <CardPerusahaan data={company} />
      </div>
    </div>
  );
};
