import "./css/card.css";

export const CardLangkah = (props) => {
  return (
    <>
      {props.data.map((v, i) => {
        return (
          <div className="card" key={i}>
            <div className="icon">
              <img
                id="img-icon"
                src={
                  process.env.PUBLIC_URL + "/img/icons/steps/" + v.icon + ".svg"
                }
                alt="icon-steps"
              />
            </div>
            <div className="title">
              <h4>{v.title}</h4>
            </div>
            <div className="desc">
              <p>{v.desc}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};
