import { Hero } from "../../components/hero/Hero";
import { NavbarMenu } from "../../components/navbar/NavbarMenu";
import { Footer } from "../../components/footer/Footer";
import "../../css/Akun.css";

export const AkunComEdit = () => {
  return (
    <>
      <NavbarMenu dashboard_login={true} dashboard={"active"} />
      <div className="account-hero" style={{ paddingBottom: 34 }}>
        <Hero
          cover={"/img/companyDashboard/bg/header.png"}
          pp={"/img/companyDashboard/pp/frame.png"}
        />
      </div>
      <div className="acc-content edit">
        <a href="#!" style={{ marginRight: 22 }}>
          Ganti Foto Profil
        </a>
        <a href="#!">Ganti Foto Cover</a>
        <div className="content-top">
          <form>
            <div className="form-top">
              <div>
                <label for="nama-perusahaan">Nama Perusahaan</label>
                <input
                  name="nama-perusahaan"
                  id="nama-perusahaan"
                  placeholder="Masukan Nama Perusahaan"
                />
              </div>
              <div>
                <label for="bidang">Bidang</label>
                <div className="select-inside">
                  <select name="bidang" id="bidang">
                    <option value="">Belum dipilih</option>
                    <option value="tech">Perusahaan Teknologi</option>
                    <option value="mesin">Perusahaan Permesinan</option>
                    <option value="lain">Lainnya</option>
                  </select>
                  <img
                    src={process.env.PUBLIC_URL + "/img/icons/SelectDown.svg"}
                    alt="select-down-icon"
                  />
                </div>
              </div>
              <div>
                <label for="about">Tentang Perusahaan</label>
                <textarea
                  name="about"
                  id="about"
                  placeholder="Deskripsikan Perusahaan Anda ..."
                  rows="6"
                />
              </div>
            </div>
            <div className="form-body">
              <h2>Informasi Tambahan</h2>
            </div>
            <div className="form-top">
              <div>
                <label for="no">Nomor Telepon</label>
                <input name="no" id="no" placeholder="Masukan Nomor Telepon" />
              </div>
              <div>
                <label for="website">Halaman Website</label>
                <input
                  name="website"
                  id="website"
                  placeholder="Masukan Halaman Website"
                />
              </div>
              <div>
                <label for="lokasi">Alamat</label>
                <textarea
                  name="lokasi"
                  id="lokasi"
                  placeholder="Masukan Alamat"
                  rows="6"
                />
              </div>
              <div style={{ gridColumnStart: 2 }}>
                <label for="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Masukan Email"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="content-bottom">
          <a href="#!">Simpan</a>
          <a href="/akun">Batal</a>
        </div>
      </div>
      <Footer />
    </>
  );
};
