export const lowongan = [
  {
    id: 1,
    companyImg: "logitech.png",
    companyName: "Logitech Indonesia",
    lokasi: "Jakarta Timur",
    title: "Digital Marketing",
    jurusan: "RPL",
    desc: "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting.",
  },
  {
    id: 2,
    companyImg: "logitech.png",
    companyName: "Logitech Indonesia",
    lokasi: "Jakarta Timur",
    title: "RPL",
    jurusan: "TKJ",
    desc: "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting.",
  },
  {
    id: 3,
    companyImg: "logitech.png",
    companyName: "Logitech Indonesia",
    lokasi: "Jakarta Timur",
    title: "Digital Marketing",
    jurusan: "RPL",
    desc: "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting.",
  },
  {
    id: 4,
    companyImg: "logitech.png",
    companyName: "Logitech Indonesia",
    lokasi: "Jakarta Timur",
    title: "Digital Marketing",
    jurusan: "RPL",
    desc: "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting.",
  },
  {
    id: 5,
    companyImg: "logitech.png",
    companyName: "Logitech Indonesia",
    lokasi: "Jakarta Timur",
    title: "Digital Marketing",
    jurusan: "RPL",
    desc: "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting.",
  },
  {
    id: 6,
    companyImg: "logitech.png",
    companyName: "Logitech Indonesia",
    lokasi: "Jakarta Timur",
    title: "Digital Marketing",
    jurusan: "RPL",
    desc: "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting.",
  },
  {
    id: 7,
    companyImg: "logitech.png",
    companyName: "Logitech Indonesia",
    lokasi: "Jakarta Timur",
    title: "Digital Marketing",
    jurusan: "RPL",
    desc: "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting.",
  },
  {
    id: 8,
    companyImg: "logitech.png",
    companyName: "Logitech Indonesia",
    lokasi: "Jakarta Timur",
    title: "Digital Marketing",
    jurusan: "RPL",
    desc: "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting.",
  },
  {
    id: 9,
    companyImg: "logitech.png",
    companyName: "Logitech Indonesia",
    lokasi: "Jakarta Timur",
    title: "Digital Marketing",
    jurusan: "RPL",
    desc: "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting.",
  },
];
