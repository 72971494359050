import provinsi from "../data/wilayah/provinces.json";
import kodepos from "../data/wilayah/kodepos.json";
import { useState } from "react";

export const Lokasi = () => {
  const [select, setSelect] = useState("0");
  const [select1, setSelect1] = useState("0");
  const [select2, setSelect2] = useState("0");
  const [select3, setSelect3] = useState("0");
  let kota = require("../data/wilayah/regencies/" + select + ".json");
  let kecamatan = require("../data/wilayah/districts/" + select1 + ".json");
  let kelurahan = require("../data/wilayah/villages/" + select2 + ".json");
  let namaKelurahan = "";

  return (
    <>
      <select
        value={select}
        onChange={(e) => {
          setSelect(e.target.value);
        }}
      >
        <option value="def">defaultValue</option>
        {provinsi.map((value, i) => {
          return (
            <option key={i} value={value.id}>
              {value.name}
            </option>
          );
        })}
      </select>
      <select
        value={select1}
        onChange={(e) => {
          setSelect1(e.target.value);
        }}
      >
        <option value="def">defaultValue</option>
        {kota.map((value, i) => {
          return (
            <option key={i} value={value.id}>
              {value.name}
            </option>
          );
        })}
      </select>
      <select
        value={select2}
        onChange={(e) => {
          setSelect2(e.target.value);
        }}
      >
        <option value="def">defaultValue</option>
        {kecamatan.map((value, i) => {
          return (
            <option key={i} value={value.id}>
              {value.name}
            </option>
          );
        })}
      </select>
      <select
        value={select3}
        onChange={(e) => {
          setSelect3(e.target.value);
        }}
      >
        <option value="def">defaultValue</option>
        {kelurahan.map((value, i) => {
          if (select3 === value.id) {
            namaKelurahan = value.name;
          }
          return (
            <option key={i} value={value.id}>
              {value.name}
            </option>
          );
        })}
      </select>
      <input list="kode-pos" name="kode-pos" />
      <datalist id="kode-pos">
        {kodepos.map((v, i) => {
          if (namaKelurahan.replace(/ /g, "") === v.nama.replace(/ /g, "")) {
            return <option value={v.kode}>{v.kode}</option>;
          }
        })}
      </datalist>
    </>
  );
};
