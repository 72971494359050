import { Hero } from "../../components/hero/Hero";
import { useParams } from "react-router-dom";
import "../../css/detailLow.css";
import { lowongan } from "../../data/vacancy-dummy";
import { Footer } from "../../components/footer/Footer";
import { NavbarMenu } from "../../components/navbar/NavbarMenu";
import hashids from "../../components/Hash";

export const VacancyComDetail = () => {
  let { hash } = useParams();
  //   let id = String(hashids.decode(hash)[1]);
  let id = 1;
  return (
    <>
      <NavbarMenu dashboard_login={true} lowongan={"active"} />
      <div className="detail-lowongan-container navbar-expad">
        <Hero
          cover={"/img/hero/cover/cover.svg"}
          pp={"/img/company/pp/" + lowongan[id].companyImg}
        />
        <div className="low-co">
          <div className="head">
            <div className="title">
              <h1>{lowongan[id].title}</h1>
              <div className="right">
                <a href="/dashboard/lowongan/1/edit">Ubah</a>
                <a href="#!">Hapus</a>
              </div>
            </div>
            <div className="bio">
              <h3 id="company-name">
                <a href="#!">{lowongan[id].companyName}</a>
              </h3>
              <h3 id="locationCompany">{lowongan[id].lokasi}</h3>
            </div>
            <div className="dateCreated">
              <h3>Pada 20 maret 2021</h3>
            </div>
          </div>
          <div className="detail-info">
            <div className="describe">
              <h2>Deskripsi</h2>
              <p>
                Qasuary merupakan portal karir yang dikembangkan untuk
                mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan
                sekaligus Alumni dengan fitur-fitur eksklusif dan relevan bagi
                setiap stakeholdernya. Qasuary merupakan portal karir yang
                dikembangkan untuk mengintegrasikan BKK (Koordinator Karir SMK),
                Perusahaan sekaligus Alumni dengan fitur-fitur eksklusif dan
                relevan bagi setiap stakeholdernya.
              </p>
            </div>
            <div className="condition">
              <h2>Persyaratan</h2>
              <ul>
                <li>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Tenetur, doloremque?
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Asperiores quis deserunt est. Quo quod reiciendis quae
                  tenetur, eligendi cupiditate praesentium fugit sunt similique.
                  Ea, quaerat.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Earum, eaque magnam quaerat sequi porro accusantium.
                </li>
              </ul>
            </div>
            <div className="more">
              <h2>Informasi Tambahan</h2>
              <div className="moreInfo">
                <div className="list 1">
                  <h3>Total seleksi</h3>
                  <p>4 Tahapan seleksi</p>
                </div>
                <div className="list 2">
                  <h3>Jadwal Pelaksanaan</h3>
                  <p>14 April 2022, 10:00 WIB</p>
                </div>
                <div className="list 3">
                  <h3>Lokasi Pelaksanaan</h3>
                  <p>Gedung RPS SMKN 1 KOTA BEKASI</p>
                </div>
              </div>
            </div>
            <div className="lamar">
              <button id="lamar-btn">Lamar</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
