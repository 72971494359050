import "./css/HeaderAlter.css";

export const HeaderAlter = (props) => {
  return (
    <>
      <div className={"header-alter " + props.banner}>
        <div className="text-con">
          <h1>{props.title}</h1>
          <p>{props.text}</p>
        </div>
      </div>
    </>
  );
};
