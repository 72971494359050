import { useState, useEffect } from "react";
import { CardPerusahaan } from "../components/cards/CardPerusahaan";
import { HeaderAlter } from "../components/container/HeaderAlter";
import { NavbarMenu } from "../components/navbar/NavbarMenu";
import { Footer } from "../components/footer/Footer";
import axios from "axios";

// const perusahaan = [
//   {
//     img: "logitech.png",
//     name: "Logitech Indonesia",
//     desc: "Didirikan pada tahun 1981 di Lausanne, Swiss, dan dengan pesat berkembang hingga ke Silicon Valley.",
//     location: "Jakarta Timur",
//   },
//   {
//     img: "samsung.png",
//     name: "Samsung Indonesia",
//     desc: "Samsung berkomitmen untuk mematuhi hukum dan menerapkan kode etik global yang ketat kepada semua karyawan.",
//     location: "Cikarang",
//   },
//   {
//     img: "rexus.jpg",
//     name: "Rexus Indonesia",
//     desc: "Rexus adalah salah satu nama merk produk aksesoris computer yang dimiliki oleh Perusahaan kami selain dari nama . . . ",
//     location: "Bogor",
//   },
//   {
//     img: "logitech.png",
//     name: "Logitech Indonesia",
//     desc: "Didirikan pada tahun 1981 di Lausanne, Swiss, dan dengan pesat berkembang hingga ke Silicon Valley.",
//     location: "Jakarta Timur",
//   },
//   {
//     img: "samsung.png",
//     name: "Samsung Indonesia",
//     desc: "Samsung berkomitmen untuk mematuhi hukum dan menerapkan kode etik global yang ketat kepada semua karyawan.",
//     location: "Cikarang",
//   },
//   {
//     img: "rexus.jpg",
//     name: "Rexus Indonesia",
//     desc: "Rexus adalah salah satu nama merk produk aksesoris computer yang dimiliki oleh Perusahaan kami selain dari nama . . . ",
//     location: "Bogor",
//   },
//   {
//     img: "logitech.png",
//     name: "Logitech Indonesia",
//     desc: "Didirikan pada tahun 1981 di Lausanne, Swiss, dan dengan pesat berkembang hingga ke Silicon Valley.",
//     location: "Jakarta Timur",
//   },
//   {
//     img: "samsung.png",
//     name: "Samsung Indonesia",
//     desc: "Samsung berkomitmen untuk mematuhi hukum dan menerapkan kode etik global yang ketat kepada semua karyawan.",
//     location: "Cikarang",
//   },
// ];

export const Company = () => {
  const [company, setCompany] = useState([]);

  const fetchCompany = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/test/get/company")
      .then(function (response) {
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <>
      <NavbarMenu perusahaan={"active"} />
      <div className="con gray navbar-expad">
        <HeaderAlter
          title={"Perusahaan"}
          text={
            "Semua Perusahaan yang sudah bekerja sama dengan pihak BKK akan tampil disini."
          }
          search={"hide"}
        />
        <div className="company">
          <div className="contain-co">
            <CardPerusahaan data={company} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
