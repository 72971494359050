import { Hero } from "../../components/hero/Hero";
import { NavbarMenu } from "../../components/navbar/NavbarMenu";
import { Footer } from "../../components/footer/Footer";
import "../../css/Akun.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const Akun = () => {
  const navigate = useNavigate();

  const handleLogout = (e) => {
    try {
      e.preventDefault();
      axios.post(process.env.REACT_APP_API + "/test/logout");
      navigate("/");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <NavbarMenu akun={"active"} />
      <div className="account-hero">
        <Hero
          cover={"/img/account/cover/cover.svg"}
          pp={"/img/account/pp/pp.png"}
        />
      </div>
      <div className="acc-content">
        <div className="top">
          <div className="left">
            <h1>John Doe</h1>
            <h2>Kuliah di UNIBBA</h2>
          </div>
          <div className="right">
            <a href="/akun/edit">Ubah</a>
            <a href="javascript:void(0)" onClick={handleLogout}>
              Keluar
            </a>
          </div>
        </div>
        <div className="body">
          <div className="top-body">
            <h2>Ringkasan</h2>
            <p>
              Qasuary merupakan portal karir yang dikembangkan untuk
              mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan sekaligus
              Alumni dengan fitur-fitur eksklusif dan relevan bagi setiap
              stakeholdernya. Qasuary merupakan portal karir yang dikembangkan
              untuk mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan
              sekaligus Alumni dengan fitur-fitur eksklusif dan relevan bagi
              setiap stakeholdernya.
            </p>
          </div>
          <div className="body-body">
            <div className="left">
              <h2>Data Pribadi</h2>
              <div>
                <h3>Tempat & Tanggal Lahir</h3>
                <h4>Bogor, 12 Januari 2004</h4>
                <h3>Jenis Kelamin</h3>
                <h4>Laki - Laki</h4>
                <h3>Jurusan</h3>
                <h4>Rekayasa Perangkat Lunak</h4>
                <h3>Tinggi</h3>
                <h4>175 cm</h4>
                <h3>Berat</h3>
                <h4>55 Kg</h4>
                <h3>Agama</h3>
                <h4>Islam</h4>
              </div>
            </div>
            <div className="right">
              <h2>Kontak</h2>
              <div>
                <h3>Nomor Telepon</h3>
                <h4>081284457668</h4>
                <h3>Email</h3>
                <h4>erlanggamuhammad12@gmail.com</h4>
                <h3>Alamat</h3>
                <h4>Jl. Merak 4 Rt002/003 No.3, Kota Baru Bekasi Barat</h4>
              </div>
            </div>
          </div>
          <div className="foot-body">
            <div className="pengalaman">
              <h2>Pengalaman</h2>
              <div>
                <h3>Junior Front-End developer - Full Time</h3>
                <h4>PT.ARGA ARGA UTAMA ( 12 Juni 2021 - 14 Juni 2021 )</h4>
                <p>
                  Qasuary merupakan portal karir yang dikembangkan untuk
                  mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan
                  sekaligus Alumni dengan fitur-fitur eksklusif dan relevan bagi
                  setiap stakeholdernya. Qasuary merupakan portal karir yang
                  dikembangkan untuk mengintegrasikan BKK (Koordinator Karir
                  SMK), Perusahaan sekaligus Alumni dengan fitur-fitur eksklusif
                  dan relevan bagi setiap stakeholdernya.
                </p>
              </div>
            </div>
            <div className="pendidikan">
              <h2>Pendidikan</h2>
              <div>
                <h3>SMKN 1 Kota Bekasi (Akuntasi)</h3>
                <h4>1993-1996</h4>
              </div>
            </div>
            <div className="berkas">
              <h2>Berkas Pendukung</h2>
              <a href="#!">
                <div>
                  <i>Document</i>
                  <h5>Nilai Alumni.pdf</h5>
                </div>
                <i>Eye</i>
              </a>
              <a href="#!">
                <div>
                  <i>Document</i>
                  <h5>Ijazah.pdf</h5>
                </div>
                <i>Eye</i>
              </a>
              <a href="#!">
                <div>
                  <i>Document</i>
                  <h5>Sertifikat.pdf</h5>
                </div>
                <i>Eye</i>
              </a>
            </div>
          </div>
        </div>
        <div className="mobile-btn">
          <a href="#!">Ubah</a>
          <a href="#!">Keluar</a>
        </div>
      </div>
      <Footer />
    </>
  );
};
