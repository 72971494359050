import { CardLangkah } from "../cards/CardLangkah";

let langkah = [
    {
        icon : "key",
        title : "Login Alumni",
        desc : "Alumni melakukan login dengan akun dan password yang diberikan oleh pihak BKK."
    },
    {
        icon : "note",
        title : "Lengkapi data diri",
        desc : "Alumni melengkapi data diri pada halaman edit profil."
    },
    {
        icon : "folder",
        title : "Lengkapi berkas",
        desc : "Alumni melengkapi berkas-berkas yang diperlukan seperti cv, ijazah, dan nilai."
    },
    {
        icon : "clipboard-text",
        title : "Daftar Lowongan",
        desc : "Alumni mendaftar pada lowongan yang ingin diikuti."
    },
    {
        icon : "tick-square",
        title : "Verifikasi BKK",
        desc : "Alumni melakukan verifikasi kepada pihak BKK SMKN 1 KOTA BEKASI."
    },
    {
        icon : "task-square",
        title : "Ikuti Seleksi",
        desc : "Alumni dapat melakukan seleksi pada lowongan yang diikuti."
    },
]

export const Steps = () => {
  return (
    <div className="steps">
        <h2>Langkah pendaftaran</h2>
        <div className="contain-co">
            <CardLangkah data={langkah} />
        </div>
    </div>
  )
}