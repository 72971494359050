import { useState } from "react";
import "../css/Login.css";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import axios from "axios";

export const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(process.env.REACT_APP_API + "/test/login", {
        username: username,
        password: password,
        check: checked,
      });
      navigate("/");
    } catch (err) {
      console.log(err);
      const msgTemp = err.response.data.message
        ? err.response.data.message
        : err;
      setMessage(msgTemp);
    }
  };

  document.body.classList.add("login");
  document.title = "qasuary - Masuk";
  return (
    <main id="login">
      <div className="bg">
        {/* <div id="circle"></div> */}
        <img
          id="logo"
          src={process.env.PUBLIC_URL + "qasuary-white.png"}
          alt=""
        />
      </div>
      <div className="login-container">
        <div className="login-box">
          <h1>Masuk Akun</h1>
          <form action="" method="POST" onSubmit={handleFormSubmit}>
            <div className="filterCtrl">
              <label htmlFor="email">Alamat E-mail</label>
              <input
                name="email"
                type="email"
                value={username}
                placeholder="Masukkan E-mail . ."
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                autoComplete="off"
              />
            </div>
            <div className="filterCtrl">
              <label htmlFor="password">Password</label>
              <input
                name="password"
                type="password"
                value={password}
                placeholder="Masukkan Password . ."
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                autoComplete="off"
              />
            </div>
            {message ? <div>{message}</div> : ""}
            <div className="filterCtrl">
              <Container fluid>
                <Row>
                  <Col>
                    <Form.Check
                      inline
                      label="Ingat saya"
                      name="ingat"
                      checked={checked}
                      onChange={(e) => setChecked(e.currentTarget.checked)}
                    />
                  </Col>
                  <Col className="pass">
                    <a href="#!">Lupa password</a>
                  </Col>
                </Row>
              </Container>
            </div>
            <button type="submit" className="filter-btn apply-filter">
              Masuk
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};
