import "./css/card.css";

export const CardBerita = (props) => {
  return (
    <>
      {props.data.map((v, i) => {
        return (
          <div className="card-berita" key={i}>
            <img
              src={process.env.REACT_APP_API + "/static/img/" + v.image}
              alt="bg-berita"
            />
            <div className="berita-text">
              <span>
                {new Date(v.created_at).toLocaleString("id", {
                  weekday: "long",
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }) +
                  " " +
                  ("0" + new Date(v.created_at).getHours()).substr(-2) +
                  ":" +
                  ("0" + new Date(v.created_at).getMinutes()).substr(-2)}
              </span>
              <h3>{v.title}</h3>
              <a href={process.env.PUBLIC_URL + "/informasi/" + v.id}>
                Baca Selengkapnya
              </a>
            </div>
          </div>
        );
      })}
    </>
  );
};
