import kampus from "../data/uni.json";
import { useState } from "react";

let hasil;
export const Uni = () => {
  const [select, setSelect] = useState("");
  let data = kampus["data"].sort();
  if (select !== "") {
    hasil = data.filter((item) =>
      item.toLowerCase().includes(select.toLowerCase())
    );
  } else {
    hasil = data;
  }
  return (
    <>
      <input
        list="uni"
        name="uni"
        value={select}
        onChange={(e) => {
          setSelect(e.target.value);
        }}
        placeholder="Masukan Nama Universitas"
      />
      <datalist id="uni">
        {hasil.map((value, i) => {
          return (
            <option key={i} value={value}>
              {value}
            </option>
          );
        })}
      </datalist>
    </>
  );
};
