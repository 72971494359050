import { HeaderAlter } from "../components/container/HeaderAlter";
import { NavbarMenu } from "../components/navbar/NavbarMenu";
import { Footer } from "../components/footer/Footer";
export const About = () => {
  return (
    <>
      <NavbarMenu />
      <main>
        <div className="con gray navbar-expad">
          <HeaderAlter title={"Tentang qasuary"} search={"hide"} />
          <div className="low-co">
            <div className="detail-info">
              <div className="describe">
                <p>
                  Qasuary merupakan portal karir yang dikembangkan untuk
                  mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan
                  sekaligus Alumni dengan fitur-fitur eksklusif dan relevan bagi
                  setiap stakeholdernya. Qasuary merupakan portal karir yang
                  dikembangkan untuk mengintegrasikan BKK (Koordinator Karir
                  SMK), Perusahaan sekaligus Alumni dengan fitur-fitur eksklusif
                  dan relevan bagi setiap stakeholdernya.
                </p>
                <p>
                  Qasuary merupakan portal karir yang dikembangkan untuk
                  mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan
                  sekaligus Alumni dengan fitur-fitur eksklusif dan relevan bagi
                  setiap stakeholdernya. Qasuary merupakan portal karir yang
                  dikembangkan untuk mengintegrasikan BKK (Koordinator Karir
                  SMK), Perusahaan sekaligus Alumni dengan fitur-fitur eksklusif
                  dan relevan bagi setiap stakeholdernya.
                </p>
                <p>
                  Qasuary merupakan portal karir yang dikembangkan untuk
                  mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan
                  sekaligus Alumni dengan fitur-fitur eksklusif dan relevan bagi
                  setiap stakeholdernya. Qasuary merupakan portal karir yang
                  dikembangkan untuk mengintegrasikan BKK (Koordinator Karir
                  SMK), Perusahaan sekaligus Alumni dengan fitur-fitur eksklusif
                  dan relevan bagi setiap stakeholdernya.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
