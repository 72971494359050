import { useState, useEffect } from "react";
import { CardBerita } from "../components/cards/CardBerita";
import { HeaderAlter } from "../components/container/HeaderAlter";
import { NavbarMenu } from "../components/navbar/NavbarMenu";
import { Footer } from "../components/footer/Footer";
import axios from "axios";

// const perusahaan = [
//   {
//     img: "logitech.png",
//     name: "Logitech Indonesia",
//     desc: "Didirikan pada tahun 1981 di Lausanne, Swiss, dan dengan pesat berkembang hingga ke Silicon Valley.",
//     location: "Jakarta Timur",
//   },
//   {
//     img: "samsung.png",
//     name: "Samsung Indonesia",
//     desc: "Samsung berkomitmen untuk mematuhi hukum dan menerapkan kode etik global yang ketat kepada semua karyawan.",
//     location: "Cikarang",
//   },
//   {
//     img: "rexus.jpg",
//     name: "Rexus Indonesia",
//     desc: "Rexus adalah salah satu nama merk produk aksesoris computer yang dimiliki oleh Perusahaan kami selain dari nama . . . ",
//     location: "Bogor",
//   },
//   {
//     img: "logitech.png",
//     name: "Logitech Indonesia",
//     desc: "Didirikan pada tahun 1981 di Lausanne, Swiss, dan dengan pesat berkembang hingga ke Silicon Valley.",
//     location: "Jakarta Timur",
//   },
//   {
//     img: "samsung.png",
//     name: "Samsung Indonesia",
//     desc: "Samsung berkomitmen untuk mematuhi hukum dan menerapkan kode etik global yang ketat kepada semua karyawan.",
//     location: "Cikarang",
//   },
//   {
//     img: "rexus.jpg",
//     name: "Rexus Indonesia",
//     desc: "Rexus adalah salah satu nama merk produk aksesoris computer yang dimiliki oleh Perusahaan kami selain dari nama . . . ",
//     location: "Bogor",
//   },
//   {
//     img: "logitech.png",
//     name: "Logitech Indonesia",
//     desc: "Didirikan pada tahun 1981 di Lausanne, Swiss, dan dengan pesat berkembang hingga ke Silicon Valley.",
//     location: "Jakarta Timur",
//   },
//   {
//     img: "samsung.png",
//     name: "Samsung Indonesia",
//     desc: "Samsung berkomitmen untuk mematuhi hukum dan menerapkan kode etik global yang ketat kepada semua karyawan.",
//     location: "Cikarang",
//   },
// ];

export const Informasi = () => {
  const [news, setNews] = useState([]);

  const fetchCompany = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/test/get/news")
      .then(function (response) {
        setNews(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <>
      <NavbarMenu informasi={"active"} />
      <main>
        <div className="con gray navbar-expad">
          <HeaderAlter
            title={"Informasi"}
            text={"Dapatkan informasi yang paling baru disini."}
            search={"hide"}
          />
          <div className="news">
            <div className="contain-co">
              <CardBerita data={news} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
