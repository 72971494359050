import { render } from "react-dom";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./css/reset.css";
import "./css/style.css";
import { Errors } from "./components/Errors";
import { Home } from "./pages/Home";
import { Akun } from "./pages/akun/Akun";
import { DashboardCom } from "./pages/dashboard/DashboardCom";
import { AkunCom } from "./pages/dashboard/AkunCom";
import { AkunComEdit } from "./pages/dashboard/AkunComEdit";
import { VacancyComDetail } from "./pages/dashboard/VacancyComDetail";
import { VacancyComNew } from "./pages/dashboard/VacancyComNew";
import { VacancyComEdit } from "./pages/dashboard/VacancyComEdit";
import { AkunEdit } from "./pages/akun/AkunEdit";
import { Lokasi } from "./pages/Lokasi";
import { Uni } from "./pages/Uni";
import { Login } from "./pages/Login";
import { Informasi } from "./pages/Informasi";
import { Company } from "./pages/Company";
import { Vacancy } from "./pages/Vacancy";
import { News } from "./pages/News";
import axios from "axios";
import { Detail } from "./pages/Detail";
import { DetailCom } from "./pages/DetailCom";
import { About } from "./pages/About";
//=====================================================================================
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENT,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
//=====================================================================================
axios.defaults.withCredentials = true;

const rootElement = document.getElementById("root");
render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={
            <div style={{ width: "inherit" }}>
              <main className="flex">
                <Errors type="NoPages" msg="Halaman tidak ditemukan" />
              </main>
            </div>
          }
        />
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="perusahaan" element={<Company />} />
        {/* <Route path="perusahaan/detail/:id" element={<DetailCom />} /> */}
        <Route path="perusahaan/:hash" element={<DetailCom />} />
        <Route path="lowongan" element={<Vacancy />} />
        {/* <Route path="lowongan/detail/:id" element={<Detail />} /> */}
        <Route path="lowongan/:hash" element={<Detail />} />
        <Route path="informasi/" element={<Informasi />} />
        <Route path="informasi/:id" element={<News />} />
        <Route path="lokasi" element={<Lokasi />} />
        <Route path="uni" element={<Uni />} />
        <Route path="akun" element={<Akun />} />
        <Route path="dashboard" element={<DashboardCom />} />
        <Route path="dashboard/akun" element={<AkunCom />} />
        <Route path="dashboard/akun/edit" element={<AkunComEdit />} />
        <Route path="dashboard/lowongan/1" element={<VacancyComDetail />} />
        <Route path="dashboard/lowongan/new" element={<VacancyComNew />} />
        <Route path="dashboard/lowongan/1/edit" element={<VacancyComEdit />} />
        <Route path="tentang" element={<About />} />
        <Route path="akun/edit" element={<AkunEdit />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  rootElement
);
