import "../css/News.css";
import { Footer } from "../components/footer/Footer";
import { NavbarMenu } from "../components/navbar/NavbarMenu";

export const News = () => {
  return (
    <>
      <div className="news-padd">
        <NavbarMenu informasi={"active"} />
        <div
          className="cover"
          style={{
            background: `url(${process.env.PUBLIC_URL}/img/news/bg.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="content">
          <div className="parag">
            <h1>Hasil Seleksi Alumni PT.UNILEVER</h1>
            <h3>Pada 20 Maret 2021</h3>
            <p>
              Qasuary merupakan portal karir yang dikembangkan untuk
              mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan sekaligus
              Alumni dengan fitur-fitur eksklusif dan relevan bagi setiap
              stakeholdernya. Qasuary merupakan portal karir yang dikembangkan
              untuk mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan
              sekaligus Alumni dengan fitur-fitur eksklusif dan relevan bagi
              setiap stakeholdernya.
            </p>
            <p>
              Qasuary merupakan portal karir yang dikembangkan untuk
              mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan sekaligus
              Alumni dengan fitur-fitur eksklusif dan relevan bagi setiap
              stakeholdernya. Qasuary merupakan portal karir yang dikembangkan
              untuk mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan
              sekaligus Alumni dengan fitur-fitur eksklusif dan relevan bagi
              setiap stakeholdernya.
            </p>
            <p>
              Qasuary merupakan portal karir yang dikembangkan untuk
              mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan sekaligus
              Alumni dengan fitur-fitur eksklusif dan relevan bagi setiap
              stakeholdernya. Qasuary merupakan portal karir yang dikembangkan
              untuk mengintegrasikan BKK (Koordinator Karir SMK), Perusahaan
              sekaligus Alumni dengan fitur-fitur eksklusif dan relevan bagi
              setiap stakeholdernya.
            </p>
          </div>
          <div className="lampiran">
            <h2>Lampiran</h2>
            <a href="#!">
              <i>Document</i>
              <h4>Lampiran.pdf</h4>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
