import { useEffect, useState } from "react";
import { CardBerita } from "../cards/CardBerita";
import axios from "axios";

export const Info = () => {
  const [news, setNews] = useState([]);
  const fetch = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/test/get/news")
      .then((response) => {
        setNews(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="card-info pad-sec">
      <div className="inline">
        <h2>Informasi</h2>
        <a id="lihatSemua" href={process.env.PUBLIC_URL + "/informasi"}>
          Lihat semua
        </a>
      </div>
      <div className="contain-co">
        <CardBerita data={news} />
      </div>
    </div>
  );
};
