export const perusahaan = [
    {
        id: 1,
        img: "logitech.png",
        name: "Logitech Indonesia",
        type: "perusahaan teknologi",
        desc: "Didirikan pada tahun 1981 di Lausanne, Swiss, dan dengan pesat berkembang hingga ke Silicon Valley. Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem repellendus eaque, assumenda reprehenderit laborum quas! Voluptatibus voluptatem numquam et aliquid minima vitae ipsum ut dolorum tempora, nisi porro nam consequuntur laborum cumque qui iure pariatur architecto perferendis! Non minima quaerat facere alias enim beatae ex maxime nulla possimus provident. Reiciendis.",
        location: "Jakarta Timur",
        web: "www.samsung.co.id",
        email: "samsung_indonesia@gmail.com",
        num: "087788889999"
    },
    {
        id: 2,
        img: "samsung.png",
        name: "Samsung Indonesia",
        type: "perusahaan teknologi",
        desc: "Samsung berkomitmen untuk mematuhi hukum dan menerapkan kode etik global yang ketat kepada semua karyawan. Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem repellendus eaque, assumenda reprehenderit laborum quas! Voluptatibus voluptatem numquam et aliquid minima vitae ipsum ut dolorum tempora, nisi porro nam consequuntur laborum cumque qui iure pariatur architecto perferendis! Non minima quaerat facere alias enim beatae ex maxime nulla possimus provident. Reiciendis.",
        location: "Cikarang",
        web: "www.samsung.co.id",
        num: "087788889999",
        email: "samsung_indonesia@gmail.com",

    },
    {
        id: 3,
        img: "rexus.jpg",
        name: "Rexus Indonesia",
        type: "perusahaan teknologi",
        desc: "Rexus adalah salah satu nama merk produk aksesoris computer yang dimiliki oleh Perusahaan kami selain dari nama Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem repellendus eaque, assumenda reprehenderit laborum quas! Voluptatibus voluptatem numquam et aliquid minima vitae ipsum ut dolorum tempora, nisi porro nam consequuntur laborum cumque qui iure pariatur architecto perferendis! Non minima quaerat facere alias enim beatae ex maxime nulla possimus provident. Reiciendis. ",
        location: "Bogor",
        web: "www.samsung.co.id",
        num: "087788889999",
        email: "samsung_indonesia@gmail.com",
    },
];
