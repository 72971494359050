import { useEffect, useState } from "react";
import { CardLowongan } from "../cards/CardLowongan";
import axios from "axios";

export const Vacancy = () => {
  const [lowongan, setLowongan] = useState([]);

  const fetch = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/test/get/opening")
      .then((response) => {
        setLowongan(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <div className="card-lowongan pad-sec">
        <div className="inline">
          <h2>Lowongan</h2>
          <a id="lihatSemua" href={process.env.PUBLIC_URL + "/lowongan"}>
            Lihat semua
          </a>
        </div>
        <div className="contain-co">
          <CardLowongan data={lowongan} />
        </div>
      </div>
    </>
  );
};
