import { useState, useEffect } from "react";
import { perusahaan } from "../data/company-dummy";
import { useParams } from "react-router-dom";
import { Hero } from "../components/hero/Hero";
import "../css/DetailCom.css";
import { Footer } from "../components/footer/Footer";
import { CardDetailPerusahaan } from "../components/cards/CardDetailPerusahaan";
import axios from "axios";
import hashids from "../components/Hash";
import { NavbarMenu } from "../components/navbar/NavbarMenu";

export const DetailCom = () => {
  const [company, setCompany] = useState([]);
  let { hash } = useParams();
  let id = String(hashids.decode(hash)[1]);

  const fetchCompany = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/test/get/company/" + id)
      .then(function (response) {
        setCompany(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <>
      <NavbarMenu perusahaan={"active"} />
      <div className="detail-company-container navbar-expad">
        <Hero
          cover={"/img/hero/cover/cover.svg"}
          pp={
            process.env.REACT_APP_API + "/static/img/" + company.profile_picture
          }
        />
        <div className="com-co">
          <div className="title com">
            <h1>{company.name}</h1>
            <div className="type">
              <h3>{perusahaan[id].type}</h3>
            </div>
          </div>
          <div className="about-com">
            <h2>Tentang</h2>
            <p>{company.description}</p>
          </div>
        </div>
        <CardDetailPerusahaan data={company} lokasi={{ kota: "Bekasi" }} />
      </div>
      <Footer />
    </>
  );
};
